import React, { useEffect, useState } from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import { IUser } from '../../types/models';
import * as S from './index.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useNavigate, useParams } from 'react-router';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchPatients } from '../../state/reducers/coaching';
import { useSearchParams } from 'react-router-dom';
import { Buttons, Inputs } from '@apps/common-ui';
import { tableStyleOverrides } from '../../api/utils/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PageHeader } from '../../components/common/commonStyled';

const tableColumns = [
    {
        name: 'Name',
        selector: (row: IUser) => row.firstName,
        format: (row: IUser) => `${row.firstName} ${row.lastName}`,
        sortable: true
    },
    {
        name: 'Email',
        selector: (row: IUser) => row.email,
        sortable: true
    }
];

export enum PatientFilters {
    ALL = 'ALL',
    UNASSIGNED = 'UNASSIGNED',
    ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
    DISCHARGED = 'DISCHARGED'
}

const PatientsList = () => {
    const { patients, totalPatients } = useSelector((state: RootState) => state.coaching);
    const { user } = useSelector((state: RootState) => state.session);
    const navigate = useNavigate();
    const { callAction: getPatients, done } = useActionLoader(fetchPatients);
    const [page, setPage] = useState<undefined | number>(1);
    const [pageSize, setPageSize] = useState(30);
    const [searchParams, setSearchParams] = useSearchParams();
    const [patientSearchVal, setPatientSearchVal] = useState(searchParams.get('search') || '');
    const [activeFilter, setActiveFilter] = useState<PatientFilters>(searchParams.get('filter') as PatientFilters || PatientFilters.ASSIGNED_TO_ME);

    const loadPatients = () => {
        if (user && page) {
            let params;
            if (activeFilter === PatientFilters.ASSIGNED_TO_ME) {
                params = {
                    page: page - 1, // minus 1 because the backend is 0 indexed, but the datatable starts at 1
                    size: pageSize,
                    search: patientSearchVal,
                    coachId: user.id,
                    assigned: true
                };
            } else if (activeFilter === PatientFilters.UNASSIGNED) {
                params = {
                    page: page - 1, // minus 1 because the backend is 0 indexed, but the datatable starts at 1
                    size: pageSize,
                    search: patientSearchVal,
                    assigned: false
                };
            } else {
                params = {
                    page: page - 1, // minus 1 because the backend is 0 indexed, but the datatable starts at 1
                    size: pageSize,
                    search: patientSearchVal
                };
            }
            getPatients(params);
        }
    };

    useEffect(() => {
        loadPatients();
    }, [user, page, pageSize, activeFilter]);

    // This is to update the page number when the user navigates to a page with a page number in the url
    useEffect(() => {
        const pageParam = searchParams.get('page');
        if (pageParam && pageParam !== page?.toString()) {
            const pageNum = parseInt(pageParam, 10);
            setPage(pageNum);
        }
        if (searchParams.get('search')) {
            setPatientSearchVal(searchParams.get('search') || '');
        }
        if (searchParams.get('filter')) {
            setActiveFilter(searchParams.get('filter') as PatientFilters);
        }
    }, [searchParams]);

    // Event handlers for the datatable
    const handlePageChange = (pageNum: number) => {
        if (page !== pageNum) {
            setSearchParams({ page: `${pageNum}`, search: patientSearchVal });
        }
    };

    const handlePageSizeChange = (size: number, pageNum: number) => {
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const handleSearch = (e: React.SyntheticEvent) => {
        e.preventDefault();
        loadPatients();
        setSearchParams({ page: '1', search: patientSearchVal });
    };

    return (
        <S.UsersListContainer>
            <S.PageHeader>
                <S.HeaderRow><h1>Patients</h1></S.HeaderRow>
                <S.HeaderRow>
                    <form onSubmit={handleSearch}>
                        <Inputs.Input
                          onChange={(e) => setPatientSearchVal(e.target.value)}
                          value={patientSearchVal}
                          placeholder="Search"
                          style={{ width: '200px' }}
                        />
                        <Buttons.Button
                          type="submit"
                          size="small"
                          style={{ marginLeft: '10px' }}
                        >Search
                        </Buttons.Button>
                        <Buttons.Button buttonType="secondary" size="small" onClick={() => setPatientSearchVal('')}>
                            Clear
                            <FontAwesomeIcon icon={faTimes as IconProp} />
                        </Buttons.Button>
                    </form>
                </S.HeaderRow>
            </S.PageHeader>
            <DataTable
              columns={tableColumns}
              data={patients}
              highlightOnHover
              paginationServer
              pagination
              striped
              paginationDefaultPage={page || 1}
              customStyles={tableStyleOverrides}
              paginationTotalRows={totalPatients}
              onRowClicked={(row: IUser) => navigate(`/patients/${row.id}`)}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              paginationPerPage={pageSize}
            />
        </S.UsersListContainer>
    );
};

export default PatientsList;
