import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface FilterButtonProps {
    isActive?: boolean;
}

export const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
`;

export const UsersListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
`;

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const FilterButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const FilterButton = styled(Buttons.Button)<FilterButtonProps>`
    height: 35px;
    white-space: nowrap;
    border-radius: 43px;
    padding: 2px 15px 2px 15px;
    color: ${props => props.isActive ? props.theme.text.white : props.theme.text.black};
    background: ${props => props.isActive ? props.theme.buttons.blue : props.theme.buttons.lightGrey};
    border: 1px solid ${props => props.isActive ? props.theme.buttons.blue : props.theme.buttons.grey};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
`;
