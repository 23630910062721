import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const PatientContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const PatientNav = styled.div<{expanded?: boolean}>`
    width: ${props => props.expanded ? '150px' : '60px'}};
    height: 100vh;
    border-right: 1px solid ${props => props.theme.shades.lightGrey};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${props => props.expanded ? '10px' : '80px 10px'};
    box-sizing: border-box;
    transition: width 0.2s ease-in;
`;

export const PatientNavMenu = styled.div<{expanded?: boolean}>`
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: ${props => props.expanded ? 'flex-start' : 'center'};

    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.shades.lightestGrey};
    }

    svg {
        cursor: pointer;
        color: ${props => props.theme.primary.medium};
        padding-left: ${props => props.expanded ? '5px' : '0'};
    }
`;

export const PatientNavTitle = styled.h4`
    margin: 5px 0;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
`;

export const PatientNavSubtitle = styled.p`
    margin: 5px 0;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
`;

export const PatientNavLink = styled(NavLink)<{expanded?: boolean}>`
    width: ${props => props.expanded ? '150px' : '100%'};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: ${props => props.expanded ? 'flex-start' : 'center'};
    color: ${props => props.theme.primary.medium};
    border-bottom: 1px solid ${props => props.theme.primary.medium};
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    white-space: nowrap;

    &:hover {
        background-color: ${props => props.theme.shades.lightestGrey};
    }

    &.active {
        background-color: ${props => props.theme.primary.medium};
        color: ${props => props.theme.text.white};

        &:hover {
            background-color: ${props => props.theme.primary.medium};
        }
    }

    svg {
        width: 20px;
        margin-left: 5px;
        margin-right: ${props => props.expanded ? '5px' : '0'};
    }
`;

export const PatientContentContainer = styled.div<{menuExpanded?:boolean}>`
    width: ${props => props.menuExpanded ? 'calc(100% - 150px)' : 'calc(100% - 60px)'};
    padding: 20px;
`;

export const PaitentNavTasks = styled.div<{expanded?: boolean}>`
    color: ${props => props.theme.text.black};
    background-color: ${props => props.theme.backgrounds.cream};
    font-weight: bold;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${props => props.expanded ? 'space-between' : 'center'};
    cursor: pointer;
`;

export const PatientNavTasksCount = styled.div`
    color: ${props => props.theme.text.white};
    background-color: ${props => props.theme.links.blue};
    border-radius: 50%;
    padding: 5px;
    height: 30px;
    width: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`;
