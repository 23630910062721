import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useState } from 'react';
import * as S from './index.styles';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import useWindowDimensions from '@apps/common-utilities/src/hooks/useWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';

type LinkProps = PropsWithChildren & NavLinkProps & {
    badge?: ReactNode;
}

export const SidebarNavLink = ({ children, badge, ...props }: LinkProps) => (
    <S.SidebarLink
      {...props}
      className={({ isActive }) => isActive ? 'active' : ''}
    >{children}
        {badge && <S.Badge>{badge}</S.Badge>}
    </S.SidebarLink>
);

export const SidebarSubLink = ({ children, ...props }: LinkProps) => (
    <S.SidebarSubLink
      {...props}
    >
        {children}
    </S.SidebarSubLink>
);

export const SidebarExternalLinkContainer = ({ children }: any) => (
    <S.SidebarExternalLinkContainer>
        {children}
    </S.SidebarExternalLinkContainer>
);

export const SidebarDivider = () => (
    <S.SidebarDivider />
);

export const SidebarSubHeading = ({ children }: any) => (
    <S.SidebarSubHeading>
        {children}
    </S.SidebarSubHeading>
);

export const SidebarPrivacyLinks = ({ children, ...props }: any) => (
    <S.SidebarPrivacyLinks {...props}>
        {children}
    </S.SidebarPrivacyLinks>
);

const Sidebar = ({ children }: any) => {
    const { width } = useWindowDimensions();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [lastPath, setLastPath] = useState(location.pathname);

    useEffect(() => {
        if (open && location.pathname !== lastPath) {
            setOpen(false);
        }
        setLastPath(location.pathname);
    }, [location, open]);

    return (
        <S.SidebarContainer isSidebarOpen={open}>
            {width < 1000 && (
                <S.SidebarHamburgerContainer
                  onClick={() => setOpen(!open)}
                >
                    <FontAwesomeIcon
                      icon={(open ? faClose : faBars) as IconProp}
                    />
                    <p>Menu</p>
                </S.SidebarHamburgerContainer>
            )}
            {(open || width >= 1000) && (
                <S.SidebarLinks>
                    {children}
                </S.SidebarLinks>
            )}
        </S.SidebarContainer>
    );
};

export default Sidebar;
